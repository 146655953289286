import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PageWithHeader from '../components/pageWithHeader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { richTextOptions } from '../helpers/contentfulHelpers';
import style from './about.module.scss';
import { DataComponent } from '../helpers/dataHelpers';

const About = () => {
  const data = useStaticQuery<GatsbyTypes.AboutQuery>(graphql`
    query About {
      signature: file(name: { eq: "Centeva_Signature" }) {
        publicURL
      }
      header_image: file(name: { eq: "capitol" }) {
        publicURL
      }
      assets: allContentfulAsset {
        edges {
          node {
            id
            title
            localFile {
              url
              childImageSharp {
                fluid {
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  aspectRatio
                }
              }
            }
            fluid {
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
              aspectRatio
            }
            contentful_id
          }
        }
      }
      content: allContentfulPages(filter: { name: { eq: "About" } }) {
        edges {
          node {
            id
            name
            slug
            content {
              json
            }
          }
        }
      }
    }
  `);
  return <AboutInner data={data}></AboutInner>
};

export default About;

const AboutInner: DataComponent<GatsbyTypes.AboutQuery> = ({data}) => {

  const assets = data.assets;
  const page = data.content.edges[0].node;
  const content = page?.content?.json;
  // @ts-ignore // TODO figure out why this is always 'never'
  const firstParagraph = page?.content?.json?.content.find(c => c.nodeType === 'paragraph')
    .content[0].value;

  return (
    <PageWithHeader
      title="About Centeva"
      headerImage={data?.header_image?.publicURL}
      description={firstParagraph}
      image={data?.signature?.publicURL}
    >
      <article className={`${style.articleContainer}`}>
        {documentToReactComponents(content as any, richTextOptions(assets))}
      </article>
    </PageWithHeader>
  );
}